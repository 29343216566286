import React from "react"
import { AuthContextProvider } from "../AuthContext"
import { LearnTrayProvider } from "../LearnTrayContext"
import { LoadingWrapper } from "../LoadingWrapper"

export const AppWrapper: React.FC = ({ children }) => {
  return (
    <LearnTrayProvider>
      <AuthContextProvider>
        <LoadingWrapper>{children}</LoadingWrapper>
      </AuthContextProvider>
    </LearnTrayProvider>
  )
}

export const wrapRootElement = ({ element }) => {
  return <AppWrapper>{element}</AppWrapper>
}
