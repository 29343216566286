import { CircularProgress } from "@mui/material"
import React, { useContext } from "react"

import { AuthContext } from "../AuthContext"
import * as S from "./style"

export const LoadingWrapper: React.FC = ({ children }) => {
  const { loading } = useContext(AuthContext)

  if (loading) {
    return (
      <S.Container>
        <CircularProgress color="primary" />
      </S.Container>
    )
  }

  return <>{children}</>
}
