import {
  ApolloLink,
  FetchResult,
  useApolloClient,
  useMutation,
} from "@apollo/client"
import { onError } from "@apollo/client/link/error"
import React, { useEffect, useState } from "react"
import useLocalStorage from "../../../hooks/useLocalStorage"
import {
  deleteRefreshTokenAndTokenCookiesMutation,
  refreshTokenMutation,
  tokenAuthMutation,
  verifyTokenMutation,
} from "./mutations"

const getAuthErrorLink = (onTokenInvalid: () => void) =>
  onError(({ graphQLErrors }) => {
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        if (
          ["JSONWebTokenError", "PermissionDenied"].includes(
            err?.extensions?.exception?.code
          )
        ) {
          onTokenInvalid()
          return
        }
      }
    }
  })

interface LoginVariables {
  username: string
  password: string
}

export interface AuthContextInterface {
  authenticated: boolean
  loading: boolean
  login: (
    variables: LoginVariables
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>
  refresh: () => Promise<
    FetchResult<any, Record<string, any>, Record<string, any>>
  >
  logout: () => Promise<
    FetchResult<any, Record<string, any>, Record<string, any>>
  >
}

export const AuthContext = React.createContext<AuthContextInterface>({
  authenticated: false,
  loading: true,
  login: () => undefined,
  refresh: () => undefined,
  logout: () => undefined,
})

export const AuthContextProvider: React.FC = ({ children }) => {
  const client = useApolloClient()
  const [authStored, setAuthStored] = useLocalStorage("authenticated", false)
  const [authenticated, setAuthenticated] = useState(false)
  const [loading, setLoading] = useState(true)
  const [tokenRefreshNeed, setTokenRefreshNeed] = useState(false)
  const [tokenRefreshing, setTokenRefreshing] = useState(false)
  const [tokenRefreshFailure, setTokenRefreshFailure] = useState(false)

  const [verifyToken, verifyTokenOpts] = useMutation(verifyTokenMutation, {
    errorPolicy: "all",
  })

  const [tokenAuth, tokenAuthOpts] = useMutation(tokenAuthMutation, {
    errorPolicy: "all",
  })

  const [refreshToken, refreshTokenOpts] = useMutation(refreshTokenMutation, {
    errorPolicy: "all",
  })

  const [
    deleteRefreshTokenAndToken,
    deleteRefreshTokenAndTokenOpts,
  ] = useMutation(deleteRefreshTokenAndTokenCookiesMutation, {
    errorPolicy: "all",
  })

  const verify = async () => {
    const response = await verifyToken()

    const verificationSuccess = !response.errors?.length

    setAuthenticated(verificationSuccess)

    if (verificationSuccess) {
      setLoading(false)
      setTokenRefreshNeed(false)
      setTokenRefreshing(false)
      setTokenRefreshFailure(false)
    }

    return response
  }

  const refresh = async () => {
    const response = await refreshToken()

    const refreshSuccess = !response.errors?.length

    setAuthenticated(refreshSuccess)
    setLoading(false)
    setTokenRefreshNeed(false)
    setTokenRefreshing(false)

    if (!refreshSuccess) {
      setTokenRefreshFailure(true)
      setAuthStored(false)
    } else {
      setAuthStored(true)
    }

    return response
  }

  const login = async ({ username, password }: LoginVariables) => {
    const response = await tokenAuth({
      variables: {
        username,
        password,
      },
    })

    const loginSuccess = !response.errors?.length

    setAuthenticated(loginSuccess)
    setLoading(false)
    setTokenRefreshNeed(false)
    setTokenRefreshing(false)
    setTokenRefreshFailure(false)

    if (!loginSuccess) {
      setAuthStored(false)
    } else {
      setAuthStored(true)
    }

    return response
  }

  const logout = async () => {
    setLoading(true)

    const responseDeleteRefreshTokenAndToken = await deleteRefreshTokenAndToken()

    await client.clearStore()

    const logoutSuccess = !responseDeleteRefreshTokenAndToken.errors?.length

    if (logoutSuccess) {
      setAuthenticated(!logoutSuccess)
    }

    setTokenRefreshNeed(false)
    setTokenRefreshing(false)
    setTokenRefreshFailure(false)
    setAuthStored(false)

    setLoading(false)

    return responseDeleteRefreshTokenAndToken
  }

  const onTokenError = () => {
    if (authStored && !tokenRefreshFailure) {
      setTokenRefreshNeed(true)
    }
  }

  useEffect(() => {
    client.setLink(
      ApolloLink.from([getAuthErrorLink(onTokenError), client.link])
    )
    if (authStored) {
      verify()
    } else {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    if (
      authStored &&
      tokenRefreshNeed &&
      !tokenRefreshing &&
      !tokenRefreshFailure
    ) {
      setTokenRefreshing(true)
      refresh()
    }
    if ((tokenRefreshNeed && tokenRefreshFailure) || !authStored) {
      setTokenRefreshNeed(false)
    }
  }, [tokenRefreshNeed])

  const context: AuthContextInterface = {
    login,
    refresh,
    logout,
    authenticated,
    loading: loading || tokenRefreshNeed || tokenRefreshing,
  }

  return <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
}
