import {
  ApolloClient,
  ApolloProvider,
  from,
  HttpLink,
  InMemoryCache,
} from "@apollo/client"
import React from "react"
import fetch from "cross-fetch"

const link = from([
  new HttpLink({
    uri: "http://localhost:8000/graphql",
    credentials: "include",
    fetch,
  }),
])

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link,
})

export const LearnTrayProvider: React.FC = ({ children }) => {
  return <ApolloProvider client={client}>{children}</ApolloProvider>
}
