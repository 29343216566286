import { gql } from "@apollo/client"

export const verifyTokenMutation = gql`
  mutation VerifyToken {
    verifyToken {
      payload
    }
  }
`

export const tokenAuthMutation = gql`
  mutation TokenAuth($username: String!, $password: String!) {
    tokenAuth(username: $username, password: $password) {
      token
      payload
      refreshExpiresIn
    }
  }
`

export const refreshTokenMutation = gql`
  mutation RefreshToken {
    refreshToken {
      token
      payload
      refreshExpiresIn
    }
  }
`

export const deleteRefreshTokenAndTokenCookiesMutation = gql`
  mutation DeleteRefreshTokenAndTokenCookies {
    deleteRefreshTokenCookie {
      deleted
    }
    deleteTokenCookie {
      deleted
    }
  }
`
