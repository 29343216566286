import { createTheme } from "@mui/material"
// import blue from "@mui/material"
import { pink } from "@mui/material/colors"

const theme = createTheme({
  palette: {
    background: {
      default: "#fafafa",
    },
    primary: {
      light: "#216EFF",
      main: "#216EFF",
      dark: "#216EFF",
      contrastText: "#fff",
    },
    secondary: {
      light: pink[400],
      main: pink[600],
      dark: pink[800],
      contrastText: "#000",
    },
  },
})

export default theme
