exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-add-resource-tsx": () => import("./../../../src/pages/add-resource.tsx" /* webpackChunkName: "component---src-pages-add-resource-tsx" */),
  "component---src-pages-auth-tsx": () => import("./../../../src/pages/auth.tsx" /* webpackChunkName: "component---src-pages-auth-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-creators-tsx": () => import("./../../../src/pages/creators.tsx" /* webpackChunkName: "component---src-pages-creators-tsx" */),
  "component---src-pages-docs-add-resources-tsx": () => import("./../../../src/pages/docs/add-resources.tsx" /* webpackChunkName: "component---src-pages-docs-add-resources-tsx" */),
  "component---src-pages-docs-tsx": () => import("./../../../src/pages/docs.tsx" /* webpackChunkName: "component---src-pages-docs-tsx" */),
  "component---src-pages-docs-use-learntray-database-tsx": () => import("./../../../src/pages/docs/use-learntray-database.tsx" /* webpackChunkName: "component---src-pages-docs-use-learntray-database-tsx" */),
  "component---src-pages-home-tsx": () => import("./../../../src/pages/home.tsx" /* webpackChunkName: "component---src-pages-home-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-learn-api-tsx": () => import("./../../../src/pages/learn-api.tsx" /* webpackChunkName: "component---src-pages-learn-api-tsx" */),
  "component---src-pages-learning-sources-tsx": () => import("./../../../src/pages/learning-sources.tsx" /* webpackChunkName: "component---src-pages-learning-sources-tsx" */),
  "component---src-pages-organisations-tsx": () => import("./../../../src/pages/organisations.tsx" /* webpackChunkName: "component---src-pages-organisations-tsx" */),
  "component---src-pages-plan-tsx": () => import("./../../../src/pages/plan.tsx" /* webpackChunkName: "component---src-pages-plan-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-resources-tsx": () => import("./../../../src/pages/resources.tsx" /* webpackChunkName: "component---src-pages-resources-tsx" */),
  "component---src-pages-terms-of-service-tsx": () => import("./../../../src/pages/terms-of-service.tsx" /* webpackChunkName: "component---src-pages-terms-of-service-tsx" */)
}

